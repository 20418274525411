import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';

import {RootService} from '../../services/root.service';
import {ManagementService} from '../../../functional-modules/management/management.service';
import * as _ from 'lodash';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import { switchMap, takeWhile } from 'rxjs/operators';
import {PupilModel} from '../../models/users/pupil.model';
import {ClassModel} from '../../models/school-management/class.model';
import {IDropdownData} from '../../../functional-modules/classes/_partial/interfaces/dropdown-data.interface';
import {ParentsService} from '../../../parents/parents.service';
import {ClassesService} from '../../../functional-modules/classes/classes.service';
import {AuthService} from '../../services/auth.service';
import {FinanceRequestModel} from '../../models/finances/financeRequest.model';
import { PollsService } from '../../../functional-modules/polls/polls.service';

@Component({
  selector: 'app-modules-nav',
  templateUrl: './modules-nav.component.html',
  styleUrls: ['./modules-nav.component.scss']
})
export class ModulesNavComponent implements OnInit, OnDestroy, AfterViewInit {

  children: {
    user: PupilModel,
    user_class: ClassModel,
    finance: FinanceRequestModel
  }[];
  usersDropdown: IDropdownData<any>[] = [];
  userUuid = window.localStorage.getItem('uuid');
  loaded = false;
  storageParentsChild = window.localStorage.getItem('selected-parents-child');
  selectedParentsChild: string;
  form: FormGroup;
  childInfo: {
    user: PupilModel,
    user_class: ClassModel,
    finance: FinanceRequestModel
  };
  submenuItems = {
    management: false
  };
  section = '';
  work = true;

  @ViewChild('sideMenu') sideMenu: ElementRef;

  constructor(public router: Router,
              public rootService: RootService,
              public authService: AuthService,
              public managementService: ManagementService,
              public parentsService: ParentsService,
              public classesService: ClassesService,
              public formBuilder: FormBuilder,
              public route: ActivatedRoute,
              public pollsService: PollsService,
              public elRef: ElementRef) {
    this.router
      .events
      .pipe(takeWhile(() => this.work))
      .subscribe((e) => {
        if (e instanceof NavigationEnd) {
          const pathPeaces = this.router.url.split('/');
          this.closeMenu();

          if (pathPeaces[2] in this.submenuItems) {
            this.submenuItems.management = true;
          } else {
            this.submenuItems.management = false;
          }

          if (this.section !== pathPeaces[2]) {
            this.section = pathPeaces[2];
            this.rootService.isBigMenu = true;
          }
        }
      });
  }

  ngOnInit() {
    this.getParentsChildren();

    console.log(this.selectedParentsChild)
  }

  ngAfterViewInit() {
    this.rootService.toggleOppenningMenu$
      .pipe(takeWhile(() => this.work))
      .subscribe(isOpen => {
        if (isOpen) {
          this.sideMenu.nativeElement.classList.add('open');
          this.elRef.nativeElement.classList.add('open');
        } else {
          this.sideMenu.nativeElement.classList.remove('open');
          this.elRef.nativeElement.classList.remove('open');
        }
      });
  }

  closeMenu() {
    this.rootService.toggleOppenningMenu$.next(false);
  }

  ngOnDestroy(): void {
    this.work = false;
    window.localStorage.removeItem('selected-parents-child');
  }

  getParentsChildren() {
    if (this.rootService.role.getRoleType() === 'parent') {
      this.parentsService.getChildren(this.userUuid).subscribe(children => {
        this.children = children;

        this.children.forEach(student => {
          this.usersDropdown.push({
            id: student.user.uuid,
            text: student.user.first_name,
            itemLogo: student.user.photo,
            first_name: student.user.first_name,
            last_name: student.user.last_name
          });
        });

        if (!this.storageParentsChild) {
          window.localStorage.setItem('selected-parents-child', this.usersDropdown[0] ? this.usersDropdown[0].id : '');
          this.selectedParentsChild = window.localStorage.getItem('selected-parents-child');
        } else {
          this.selectedParentsChild = this.storageParentsChild;
        }
        this.childInfo = this.children.find(child => {
          return child.user.uuid === window.localStorage.getItem('selected-parents-child');
        }) || this.children[0];

        this.childInfo.user_class ? window.localStorage.setItem('child-class-id', this.childInfo.user_class.grade.name) :
          window.localStorage.setItem('child-class-id', null);
        this.parentsService.changeChild$.next(this.childInfo);
        this.loaded = true;
      });
    } else if (this.rootService.role.getRoleType() === 'pupils') {
      this.parentsService.changeChild$.pipe(
          takeWhile(() => this.work)
      ).subscribe((child_info: { user: PupilModel, user_class: ClassModel, finance: FinanceRequestModel }) => {
        this.childInfo = child_info;
        this.loaded = true;
      });
    }
  }

  changeChild(child_uuid = null) {
    if (child_uuid === window.localStorage.getItem('selected-parents-child')) {
      return;
    }
    window.localStorage.setItem('selected-parents-child', child_uuid);
    this.selectedParentsChild = window.localStorage.getItem('selected-parents-child');
    this.childInfo = this.children.find(child => {
      return child.user.uuid === window.localStorage.getItem('selected-parents-child');
    });

    this.childInfo.user_class ? window.localStorage.setItem('child-class-id', this.childInfo.user_class.grade.name) :
      window.localStorage.setItem('child-class-id', null);

    this.authService.getNotifications();

    if (this.router.url.includes('class')) {
      const sectionArr = this.router.url.split('/');
      const section = sectionArr[sectionArr.length - 1];
      if (this.childInfo.user_class) {
        if (sectionArr[2] === 'class') {
          this.router.navigateByUrl(this.parentsService.getSectionPath('') + 'class/' + this.childInfo.user_class.id + '/' + section);
        }
      } else {
        this.router.navigateByUrl(this.parentsService.getSectionPath('parent-main-page'));
      }
    }

    this.rootService.toggleOppenningMenu$.next(false);
    this.parentsService.changeChild$.next(this.childInfo);
  }

  toggleSubMenu(itemName, path) {
    _.forOwn(this.submenuItems, (v, name) => {
      if (name !== itemName) {
        this.submenuItems[name] = false;
      }
    });

    this.submenuItems[itemName] = !this.submenuItems[itemName];

    if (this.submenuItems[itemName]) {
      this.router.navigateByUrl(path);
    }
  }

  isActive(path, exact = false) {
    return this.router.isActive(path, exact);
  }

  getItemIcon(isActive, iconName) {
    return `/assets/images/icons/left-nav-icons/${isActive ? iconName + '-active' : iconName}.svg`;
  }

  toggleSize() {
    this.rootService.toggleMenuSize();
  }

  get missedPolls() {
    return this.pollsService.missedPolls().length;
  }

}
