import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
} from '@angular/core';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PupilModel} from '../../models/users/pupil.model';
import {concatMap, takeWhile, tap} from 'rxjs/operators';
import * as _ from 'lodash';
import {Observable, Subscription, zip} from 'rxjs';
import {ClassGroupModel} from '../../models/school-management/class-group.model';
import {HelperService} from '../../services/helper.service';
import {ManagementService} from '../../../functional-modules/management/management.service';
import {ClassesService} from '../../../functional-modules/classes/classes.service';
import {UsersService} from '../../../functional-modules/users/users.service';

@Component({
  selector: 'class-group-popup',
  templateUrl: './class-group-popup.component.html',
  styleUrls: ['./class-group-popup.component.scss', '../../style/popups-shared.scss']
})
export class ClassGroupPopupComponent implements OnDestroy, AfterViewInit {

  loaded = false;
  pupils: PupilModel[] = [];
  unchangedPupilsList: PupilModel[] = [];
  group: ClassGroupModel;
  groups: ClassGroupModel[];
  lessons: any[];
  lessonsChangeSubs: Subscription;
  work = true;
  form: FormGroup;
  wasSubmitted = false;
  saving = false;
  selectFilter = {
    left: {
      b: 0,
      g: 0,
      all: 0
    },
    right: {
      b: 0,
      g: 0,
      all: 0
    }
  };
  requiredField = HelperService.formErrors.requiredField;
  teachers: any[];

  @Input() with_teachers: boolean;
  @Input() is_edit: boolean;

  constructor(public ngxSmartModalService: NgxSmartModalService,
              public usersService: UsersService,
              public changeDetectionRef: ChangeDetectorRef,
              public fb: FormBuilder,
              public managementService: ManagementService,
              public classesService: ClassesService) {
  }

  ngOnDestroy() {
    this.work = false;
    if (this.lessonsChangeSubs) {
      this.lessonsChangeSubs.unsubscribe();
    }
  }

  ngAfterViewInit() {
    this.ngxSmartModalService.getModal('class-group-popup')
      .onOpen
      .pipe(takeWhile(() => this.work))
      .subscribe(() => {
        const {classId, gradeId} = this.ngxSmartModalService.getModal('class-group-popup').getData();
        this.initForm(classId, gradeId);
      });
  }

  initForm(classId, gradeId) {
    this.loaded = false;
    this.wasSubmitted = false;
    this.saving = false;
    this.getClassGroups(classId)
      .pipe(
        takeWhile(() => this.work),
        concatMap(() => this.loadExtraInfo(classId, gradeId))
      )
      .subscribe(() => {
        const usersUuids = _.map(this.pupils, 'uuid');
        this.form = null;
        this.changeDetectionRef.detectChanges();
        if (!this.group) {
          const selectedLesson = this.ngxSmartModalService.getModal('class-group-popup').getData().lessonId;

          this.form = this.fb.group({
            lesson: [String(selectedLesson) || ''],
            left_name: ['', Validators.required],
            right_name: ['', this.isValidRightForm('right_name')],
            left_users_box: [[], Validators.compose([Validators.required, Validators.minLength(1)])],
            right_users_box: [[], this.isValidRightForm('right_users_box')],
            selected_users: [[]],
            groupCount: ['1']
          });
          if (this.with_teachers) {
            this.form.addControl('left_teacher', this.fb.control('', [Validators.required]));
          }
        } else {
          const selectedLesson = this.ngxSmartModalService.getModal('class-group-popup').getData().lessonId;

          this.form = this.fb.group({
            lesson: [String(selectedLesson)],
            left_name: [this.group.name, Validators.required],
            left_teacher: [this.group.teacher ? this.group.teacher.uuid : null, Validators.required],
            left_users_box: [[]],
            right_users_box: [[], this.isValidRightForm('right_users_box')],
            selected_users: [[]],
          });
        }
        if (!this.group) {
          this.form.get('groupCount').valueChanges.subscribe((val) => {
            this.form.get('selected_users').setValue([]);

            if (val === '1') {
              this.form.get('left_users_box').setValue([]);
              this.form.get('right_users_box').setValue(usersUuids);
              if (this.with_teachers) {
                this.form.removeControl('right_teacher');
              }
            } else if (val === '2') {
              this.form.get('right_name').reset('');
              this.form.get('left_users_box').setValue([]);
              this.form.get('right_users_box').setValue(usersUuids);
              if (this.with_teachers) {
                this.form.addControl('right_teacher', this.fb.control('', [Validators.required]));
              }
            }

            this.selectFilter = {
              left: {
                b: 0,
                g: 0,
                all: 0
              },
              right: {
                b: 0,
                g: 0,
                all: 0
              }
            };
          });

          this.form.get('left_users_box').setValue([]);
          this.form.get('right_users_box').setValue(usersUuids);
        } else {
          const groupStudentsUuids = this.group.students
              .filter(pupil => this.pupils.find(selPupil => selPupil.uuid === pupil.uuid))
              .map(student => student.uuid);
          this.form.get('left_users_box').setValue(groupStudentsUuids);
        }
        this.lessonsOnChange();
        this.loaded = true;
      });
  }

  lessonsOnChange() {
    if (!this.isFixedLesson()) {
      this.lessonsChangeSubs = this.form.get('lesson').valueChanges.subscribe(lessonId => {
        this.form.get('right_users_box').setValue([]);
        this.form.get('left_users_box').setValue([]);
        this.pupils = this.unchangedPupilsList;
        const studentsInGroupsIds = this.groups
          .filter(group => group.lesson.id === +lessonId)
          .map(group => group.students)
          .flat()
          .map(student => student.uuid);
        this.pupils = this.pupils.filter(pupil => {
          return !studentsInGroupsIds.find(id => id === pupil.uuid);
        });
        const pupilsIds = this.pupils.map(pupil => pupil.uuid);
        this.form.get('right_users_box').setValue(pupilsIds);
      });
    } else {
      const studentsInGroupsIds = this.groups
        .filter(group => group.lesson.id === +this.form.get('lesson').value)
        .map(group => group.students)
        .flat()
        .map(student => student.uuid);
      const freeStudents = this.pupils.filter(pupil => {
        return !studentsInGroupsIds.find(id => id === pupil.uuid);
      });
      const pupilsIds = freeStudents.map(pupil => pupil.uuid);
      this.form.get('right_users_box').setValue(pupilsIds);
    }
  }

  boxPupils(allPupils, selectedPupils) {
    return allPupils.filter(pupil => selectedPupils.find(selPupil => selPupil === pupil.uuid));
  }

  getClassGroups(classId) {
    return this.managementService.getClassGroups(classId).pipe(
      tap((groups) => {
        const groupId = this.ngxSmartModalService.getModal('class-group-popup').getData().groupId;

        this.groups = groups;
        this.group = null;

        if (groupId) {
          this.group = groups.find(group => group.id === groupId);

        }
      })
    );
  }

  loadExtraInfo(classId, gradeId) {
    return zip(
      this.classesService.getClassById(classId, true),
      this.managementService.getGradeLessons(gradeId),
      this.classesService.getAllTeachersListUrl(),
    ).pipe(tap(([pupils, educationPlan, teachers]) => {
      this.lessons = (_.first(educationPlan) as any).lessons.map((data) => {
        return {
          id: String(data.lesson_id),
          text: data.lesson_name,
          data
        };
      });
      this.pupils = pupils.students.map((p) => new PupilModel(p));
      this.unchangedPupilsList = pupils.students.map((p) => new PupilModel(p));
      this.teachers = teachers.map(teacher => {
        return {
          id: teacher.uuid,
          text: teacher.last_name + ' ' + teacher.first_name + ' ' + (teacher.surname ? teacher.surname : ''),
        };
      });
    }));
  }

  save() {
    const requests: Observable<any>[] = [];

    this.wasSubmitted = true;

    if (this.form.valid && !this.saving) {
      this.saving = true;

      // add new group
      if (!this.group) {
        if (this.with_teachers) {
          requests.push(this.managementService.addClassGroup({
            name: this.form.get('left_name').value,
            uuid_list: this.form.get('left_users_box').value.join(','),
            lesson: this.form.get('lesson').value,
            class_id: this.ngxSmartModalService.getModal('class-group-popup').getData().classId,
            teacher_uuid: this.form.get('left_teacher').value
          }));
        } else {
          requests.push(this.managementService.addClassGroup({
            name: this.form.get('left_name').value,
            uuid_list: this.form.get('left_users_box').value.join(','),
            lesson: this.form.get('lesson').value,
            class_id: this.ngxSmartModalService.getModal('class-group-popup').getData().classId,
          }));
        }

        if (this.form.get('groupCount').value === '2') {
          if (this.with_teachers) {
            requests.push(this.managementService.addClassGroup({
              name: this.form.get('right_name').value,
              uuid_list: this.form.get('right_users_box').value.join(','),
              lesson: this.form.get('lesson').value,
              class_id: this.ngxSmartModalService.getModal('class-group-popup').getData().classId,
              teacher_uuid: this.form.get('right_teacher').value
            }));
          } else {
            requests.push(this.managementService.addClassGroup({
              name: this.form.get('right_name').value,
              uuid_list: this.form.get('right_users_box').value.join(','),
              lesson: this.form.get('lesson').value,
              class_id: this.ngxSmartModalService.getModal('class-group-popup').getData().classId
            }));
          }
        }

        zip(...requests).subscribe(() => {
          this.classesService.updateStudentClassGroupList$.next('groups updated');
          this.cancel();

          if (this.ngxSmartModalService.getModal('class-group-popup').getData().onSave) {
            this.ngxSmartModalService.getModal('class-group-popup').getData().onSave();
          }
        }, (err) => {
          console.log(err);
          this.saving = false;
        });

        // update existing group
      } else {
        const groupId = this.ngxSmartModalService.getModal('class-group-popup').getData().groupId;
        const updatedData = {
          uuid_list: this.form.get('left_users_box').value.join(','),
          teacher_uuid: this.form.get('left_teacher').value,
          name: this.form.get('left_name').value,
          id: groupId,
        };
        this.loaded = false;
        this.classesService.editGroup(groupId, updatedData).subscribe(() => {
          this.classesService.updateStudentClassGroupList$.next('groups updated');
          this.cancel();
        }, (err) => console.log(err));
      }
    }
  }

  selectPeopleGroup(box: 'left' | 'right', type: 'all' | 'girls' | 'boys', isAdd) {
    this.changeDetectionRef.detectChanges();
    const selectedUsersControl = this.form.get('selected_users');
    const selectedUsers = _.cloneDeep(selectedUsersControl.value);
    const userUuids = [];

    if (type !== 'all' && !isAdd) {
      this.selectFilter[box].all = 0;
    }

    this.form.get(`${box}_users_box`).value.forEach((uuid) => {

      if (type === 'all') {
        userUuids.push(uuid);
      } else {
        const genderId = _.find(this.pupils, ['uuid', uuid]).gender;

        if (type === 'boys' && genderId === '1') {
          userUuids.push(uuid);
        } else if (type === 'girls' && genderId === '2') {
          userUuids.push(uuid);
        }
      }
    });

    if (isAdd) {
      userUuids.forEach((uuid) => {
        if (!selectedUsers.includes(uuid)) {
          selectedUsers.push(uuid);
        }
      });
    } else {
      selectedUsersControl.value.forEach(uuid => {
        if (userUuids.includes(uuid)) {
          _.remove(selectedUsers, (itemUuid) => itemUuid === uuid);
        }
      });
    }

    selectedUsersControl.setValue(selectedUsers);
  }

  isSelected(side: 'left' | 'right', uuid) {
    return this.form.get(`selected_users`).value.includes(uuid);
  }

  isExistsInBox(side: 'left' | 'right', uuid) {
    return this.form.get(`${side}_users_box`).value.includes(uuid);
  }

  moveUsers(direction: 'left' | 'right') {
    let currNewBox = [];
    let currSourceBox = [];

    if (direction === 'left') {
      currNewBox = _.cloneDeep(this.form.get(`left_users_box`).value);
      currSourceBox = _.cloneDeep(this.form.get(`right_users_box`).value);

      this.form.get(`right_users_box`).value.forEach((uuid) => {

        if (this.isSelected('right', uuid)) {
          currNewBox = this.form.get(`left_users_box`).value;
          currNewBox.push(uuid);

          _.remove(currSourceBox, (itemUuid) => itemUuid === uuid);
        }
      });

      this.form.get(`left_users_box`).setValue(currNewBox);
      this.form.get(`right_users_box`).setValue(currSourceBox);


    } else if (direction === 'right') {
      currNewBox = _.cloneDeep(this.form.get(`right_users_box`).value);
      currSourceBox = _.cloneDeep(this.form.get(`left_users_box`).value);

      this.form.get(`left_users_box`).value.forEach((uuid) => {

        if (this.isSelected('left', uuid)) {
          currNewBox = this.form.get(`right_users_box`).value;
          currNewBox.push(uuid);

          _.remove(currSourceBox, (itemUuid) => itemUuid === uuid);
        }
      });

      this.form.get(`right_users_box`).setValue(currNewBox);
      this.form.get(`left_users_box`).setValue(currSourceBox);
    }

    this.form.get(`selected_users`).setValue([]);
  }

  isFixedLesson() {
    return !this.ngxSmartModalService.getModal('class-group-popup').getData().canChangeLesson;
  }

  getSecondBoxTitle() {
    if (this.group && !this.form.get('right_users_box').value.length) {
      return 'Вільні учні';
    } else if (this.form.get('groupCount').value === '2' && !this.form.get('right_users_box').value.length) {
      return 'Учні нової групи 2';
    } else if (this.form.get('groupCount').value === '1' && !this.form.get('right_users_box').value.length) {
      return 'Вільні учні';
    }
  }

  onSelectPupil(uuid, isSelected) {
    if (isSelected) {
      const newValue = this.form.get('selected_users').value;
      newValue.push(uuid);
      this.form.get('selected_users').setValue(newValue);
    } else {
      const newValue = this.form.get('selected_users').value;
      _.remove(newValue, (itemUuid) => {
        return itemUuid === uuid;
      });
      this.form.get('selected_users').setValue(newValue);
    }
  }

  isValidRightSide(field) {
    if (!this.wasSubmitted) {
      return null;
    }
    if (field === 'right_users_box' && this.group) {
      return null;
    }

    if (field === 'right_name') {
      if (this.form.get('groupCount').value === '1') {
        return null;
      }
      return !!this.form.get(field).value;
    } else if (field === 'right_users_box') {
      if (this.form.get('groupCount').value === '1') {
        return null;
      }
      return !!this.form.get(field).value.length;
    } else {
      return this.form.get(field).valid;
    }
  }

  isValidLessonField(lessonField) {
    if (!this.wasSubmitted) {
      return null;
    }
    if (!this.with_teachers) {
      return null;
    }

    if (this.form.get(lessonField).value === 'undefined') {
      return false;
    } else {
      return null;
    }
  }

  isValidRightForm(field) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      return this.isValidRightSide(field) === false ? {'invalid': {value: control.value}} : null;
    };
  }

  cancel() {
    this.ngxSmartModalService.getModal('class-group-popup').close();
  }
}
