import {AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {takeWhile} from 'rxjs/operators';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {ManagementService} from '../../../functional-modules/management/management.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {IDataForMark} from '../../interfaces/journal/data-for-mark.interface';
import {ClassesService} from '../../../functional-modules/classes/classes.service';
import {Subscription, zip} from 'rxjs';
import {IDropdownData} from '../../../functional-modules/classes/_partial/interfaces/dropdown-data.interface';
import {HelperService} from '../../services/helper.service';
import * as _ from 'lodash';

@Component({
  selector: 'student-assessment',
  templateUrl: './student-assessment.component.html',
  styleUrls: ['../../style/popups-shared.scss', './student-assessment.component.scss']
})
export class StudentAssessmentComponent implements OnInit, AfterViewInit, OnDestroy, AfterViewChecked {
  loaded: boolean;
  work = true;
  isMarkedErrors = false;
  formError: any = {
    score_type: HelperService.formErrors.requiredField,
    lesson_info: HelperService.formErrors.requiredField
  };
  multScoreTypeName: string;

  markInfo: IDataForMark;
  form: FormGroup;
  currencyValue: number;
  reqSubscription: Subscription;
  scoreList: IDropdownData<any>[] = [];
  scoreTypesList: IDropdownData<any>[] = [];
  lessonInfoList: IDropdownData<any>[] = [];
  selectedIndex: number;
  isParentView: boolean;

  studentsWithMark = [];
  studentsToAssess: string[];
  dataForScoreType = [];
  file = null;
  is_visible_for_student: boolean|number;
  is_visible_for_parent: boolean|number;

  constructor(public ngxSmartModalService: NgxSmartModalService,
              public managementService: ManagementService,
              public formBuilder: FormBuilder,
              public classesService: ClassesService,
              public changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.ngxSmartModalService.getModal('student-assessment')
      .onOpenFinished
      .pipe(takeWhile(() => this.work))
      .subscribe(() => {
          this.loaded = false;
          this.scoreList = [];
          this.file = null;
          this.scoreTypesList = [];
          this.isMarkedErrors = false;
          this.studentsWithMark = [];

          this.markInfo = this.ngxSmartModalService.getModal('student-assessment').getData().mark_data;
          this.is_visible_for_student = this.markInfo.student_score ? this.markInfo.student_score.is_visible_for_student : true;
          this.is_visible_for_parent = this.markInfo.student_score ? this.markInfo.student_score.is_visible_for_parent : true;

          console.log(this.markInfo);

          if (this.markInfo.is_group_mark && !this.markInfo.is_group_all_lessons) {
            this.markInfo.score_types.forEach(score_type => {
              this.scoreTypesList.push({
                id: +score_type.id,
                text: score_type.score_name ? score_type.name + ',' + score_type.score_name : score_type.name
              });
            });
          }

          if (this.markInfo.is_group_all_lessons) {
            this.lessonInfoList = [];
            this.markInfo.lesson_data.forEach(lesson => {
              this.lessonInfoList.push({
                id: lesson.id,
                text: '№' + lesson.lesson_num + ' ' + lesson.date.split('-').splice(1, 2).reverse().join('.')
                  + ' ' + (lesson.theme ? lesson.theme.name : '')
              });
            });
          }

          const scoresReq = this.classesService.getScoreTypeByGrade(this.markInfo.grade_id);
          if (!this.markInfo.viewMode) {
            this.reqSubscription = zip(scoresReq).subscribe(([score_data]) => {
              if (!this.markInfo.is_middle_school) {
                score_data.score_list.forEach(score => {
                  _.forOwn(score, (value, key) => {
                    this.scoreList.push({
                      id: +key,
                      abbr: value.abbr,
                      text: value.abbr + ' ' + value.name
                    });
                  });
                });
              } else {
                score_data.score_list.forEach(score => {
                  _.forOwn(score, (value, key) => {
                    this.scoreList.push({
                      id: +key,
                      text: value.abbr,
                      abbr: value.abbr,
                    });
                  });
                });
              }

              const name = this.markInfo.score_type ? (this.markInfo.score_type.score_name || this.markInfo.score_type.name) : null;

              if (name && (name !== 'Семестрова оцінка' && name !== 'Річна оцінка' && name !== 'Тематична оцінка')) {
                this.scoreList.pop();
              }

              this.loaded = true;
            });
          } else {
            this.loaded = true;
          }
          this.markInfo.student_score ? this.selectedIndex = this.markInfo.student_score.score : this.selectedIndex = 0;
          this.initForm();
        }
      );

    this.ngxSmartModalService.getModal('student-assessment')
      .onClose
      .pipe(takeWhile(() => this.work))
      .subscribe(() => {
      });
  }

  ngOnDestroy() {
    if (this.reqSubscription) {
      this.reqSubscription.unsubscribe();
    }
  }

  ngAfterViewChecked() {
    this.changeDetectorRef.detectChanges();
  }


  initForm() {
    this.form = null;
  //  console.log(this.markInfo.student_score ? this.markInfo.student_score.score : null);
    this.form = this.formBuilder.group({
      comment: [this.markInfo.student_score ? this.markInfo.student_score.teacher_comment : ''],
      mark: [this.markInfo.student_score ? this.markInfo.student_score.score : '']
    });
    if (this.markInfo.is_group_mark) {
      this.form.addControl('mark', this.formBuilder.control(''));
      this.form.addControl('score_type', this.formBuilder.control('', Validators.required));
      this.scoreTypeChanges();
    }
    if (this.markInfo.is_group_mark && !this.markInfo.is_middle_school) {
      this.scoreTypeChanges();
    }
    if (this.markInfo.is_group_all_lessons) {
      this.form.addControl('lesson_info', this.formBuilder.control('', Validators.required));
      this.lessonChanges();
    }
  }

  lessonChanges() {
    this.form.get('lesson_info').valueChanges.subscribe(selected_lesson => {
      this.form.get('score_type').setValue('');
      this.dataForScoreType = this.markInfo.lesson_data.filter(lesson => lesson.id === selected_lesson)
        .map(lesson => lesson.score_types)
        .flat();
      this.scoreTypesList = [];
      this.dataForScoreType.forEach(score_type => {
        this.scoreTypesList.push({
          id: +score_type.id,
          text: score_type.score_name ? score_type.name + ',' + score_type.score_name : score_type.name
        });
      });
      this.changeDetectorRef.detectChanges();
    });
  }

  scoreTypeChanges() {
    this.form.get('score_type').valueChanges.subscribe(selected_score_type => {
      this.studentsWithMark = [];
      if (!this.markInfo.is_group_all_lessons) {
        const selectedType = this.markInfo.score_types.filter(score_type => score_type.id === selected_score_type);
        this.isParentView = selectedType.map(score_type => score_type.is_parent_view)[0];
        this.multScoreTypeName = selectedType.map(score_type => score_type.score_name)[0];
        this.markInfo.students
          .filter(student => this.markInfo.student_uuid.find(selectedStudent => selectedStudent === student.uuid))
          .forEach(student => {
            student.scores.forEach(score => {
              if (score.score_type === selected_score_type && (score.score || score.teacher_comment)) {
                this.studentsWithMark.push(student.uuid);
              }
            });
          });
      } else {
        const allStudents = this.markInfo.lesson_data.filter(lesson => lesson.id === this.form.get('lesson_info').value)[0].users;
        const selectedStudents = allStudents.filter(student => this.markInfo.student_uuid
          .find(selectedStudent => selectedStudent === student.uuid));
        selectedStudents.forEach(student => {
          student.scores.forEach(score => {
            if (score.score_type === selected_score_type && (score.score || score.teacher_comment)) {
              this.studentsWithMark.push(student.uuid);
            }
          });
        });
      }
    });
  }

  getFieldState(field, group = null) {

    if (!this.form || !this.isMarkedErrors) {
      return null;
    }

    if (group === null) {
      return this.form.get(field).valid;
    } else {
      return this.form.get(group).get(field).valid;
    }
  }

  get comment() {
    return this.form.get('comment');
  }

  clearComment() {
    this.comment.setValue('');
  }

  putMark(i) {
  //  console.log(i)
    if (+i === this.selectedIndex) {
      this.selectedIndex = null;
      this.form.get('mark').setValue(null);
    } else {
      this.selectedIndex = +i;
      this.form.get('mark').setValue(this.selectedIndex);
    }
  }

  action(type, form?: FormGroup) {
    const isMarkChanged = Object.values(form.value).concat(this.file || (this.markInfo.student_score ? this.markInfo.student_score.file_url : '')).find(item => {
      return item !== null && item !== 'null' && item !== 0 && item !== '';
    });

    if (type === 'cancel') {
      this.ngxSmartModalService.get('student-assessment').close();
    } else if (type === 'save' && (!this.markInfo.student_score
      || (this.markInfo.student_score && !this.markInfo.student_score.score && !this.markInfo.student_score.teacher_comment && !this.file))) {
      /* adding */
      if (form.invalid) {
        this.isMarkedErrors = true;
      } else {

        if (this.markInfo.is_group_mark) {
          this.studentsToAssess = this.markInfo.student_uuid.filter(item => {
            return !this.studentsWithMark.includes(item);
          });
        }

        const markData = {
          uuid_list: this.markInfo.is_group_mark ? this.studentsToAssess : this.markInfo.student.uuid,
          journal: this.markInfo.is_group_all_lessons ? this.form.get('lesson_info').value : this.markInfo.journal,
          name: this.markInfo.is_group_mark ? this.multScoreTypeName : this.markInfo.score_type.score_name,
          score: form.value.mark,
          score_type: this.markInfo.is_group_mark ? form.value.score_type : this.markInfo.score_type.id,
          teacher_comment: form.value.comment,
          file_url: this.file,
          is_parent_view: this.markInfo.is_group_mark ? this.isParentView : this.markInfo.score_type.is_parent_view,
          is_visible_for_student: this.is_visible_for_student,
          is_visible_for_parent: this.is_visible_for_parent
        };

        if (this.studentsToAssess) {
          if (this.studentsToAssess.length) {
            this.classesService.addStudentScore(markData).subscribe((response: any) => {
              if (!this.markInfo.is_middle_school) {
                this.classesService.updateJournalLessonData$.next({
                  uuid_list: this.markInfo.is_group_mark ? this.studentsToAssess : this.markInfo.student.uuid,
                  journal: this.markInfo.is_group_all_lessons ? this.form.get('lesson_info').value : this.markInfo.journal,
                  name: this.markInfo.is_group_mark ? this.multScoreTypeName : this.markInfo.score_type.score_name,
                  score: form.value.mark ? (_.find(this.scoreList, ['id', form.value.mark]) as any).abbr : null,
                  score_type: this.markInfo.is_group_mark ? form.value.score_type : this.markInfo.score_type.id,
                  teacher_comment: form.value.comment,
                  file_url: response.file_url,
                  is_parent_view: this.markInfo.is_group_mark ? this.isParentView : this.markInfo.score_type.is_parent_view,
                  is_visible_for_student: this.is_visible_for_student,
                  is_visible_for_parent: this.is_visible_for_parent
                });
              } else {
                this.classesService.updateJournalLessonData$.next({
                  uuid_list: this.markInfo.is_group_mark ? this.studentsToAssess : this.markInfo.student.uuid,
                  journal: this.markInfo.is_group_all_lessons ? this.form.get('lesson_info').value : this.markInfo.journal,
                  name: this.markInfo.is_group_mark ? this.multScoreTypeName : this.markInfo.score_type.score_name,
                  score: form.value.mark ? (_.find(this.scoreList, ['id', form.value.mark]) as any).text : null,
                  score_type: this.markInfo.is_group_mark ? form.value.score_type : this.markInfo.score_type.id,
                  teacher_comment: form.value.comment,
                  file_url: response.file_url,
                  is_parent_view: this.markInfo.is_group_mark ? this.isParentView : this.markInfo.score_type.is_parent_view,
                  is_visible_for_student: this.is_visible_for_student,
                  is_visible_for_parent: this.is_visible_for_parent
                });
              }

              if (this.ngxSmartModalService.get('student-assessment').getData().onSave) {
                this.ngxSmartModalService.get('student-assessment').getData().onSave();
              }
              this.ngxSmartModalService.get('student-assessment').close();
            }, error => {
              if (error.error?.detail) {
                alert(error.error?.detail);
              }
              this.ngxSmartModalService.get('student-assessment').close();
            });
          } else {
            this.ngxSmartModalService.get('student-assessment').close();
          }
        } else {
          this.classesService.addStudentScore(markData).subscribe((response) => {
            if (!this.markInfo.is_middle_school) {
              this.classesService.updateJournalLessonData$.next({
                uuid_list: this.markInfo.is_group_mark ? this.studentsToAssess : this.markInfo.student.uuid,
                journal: this.markInfo.is_group_all_lessons ? this.form.get('lesson_info').value : this.markInfo.journal,
                name: this.markInfo.is_group_mark ? this.multScoreTypeName : this.markInfo.score_type.score_name,
                score: form.value.mark ? (_.find(this.scoreList, ['id', form.value.mark]) as any).abbr : null,
                score_type: this.markInfo.is_group_mark ? form.value.score_type : this.markInfo.score_type.id,
                teacher_comment: form.value.comment,
                is_visible_for_student: this.is_visible_for_student,
                is_visible_for_parent: this.is_visible_for_parent,
                is_parent_view: this.markInfo.is_group_mark ? this.isParentView : this.markInfo.score_type.is_parent_view,
                    ...response});
            } else {
              this.classesService.updateJournalLessonData$.next({
                uuid_list: this.markInfo.is_group_mark ? this.studentsToAssess : this.markInfo.student.uuid,
                journal: this.markInfo.is_group_all_lessons ? this.form.get('lesson_info').value : this.markInfo.journal,
                name: this.markInfo.is_group_mark ? this.multScoreTypeName : this.markInfo.score_type.score_name,
                score: form.value.mark ? (_.find(this.scoreList, ['id', form.value.mark]) as any).text : null,
                score_type: this.markInfo.is_group_mark ? form.value.score_type : this.markInfo.score_type.id,
                teacher_comment: form.value.comment,
                is_visible_for_student: this.is_visible_for_student,
                is_visible_for_parent: this.is_visible_for_parent,
                is_parent_view: this.markInfo.is_group_mark ? this.isParentView : this.markInfo.score_type.is_parent_view,
                    ...response});
            }
            if (this.ngxSmartModalService.get('student-assessment').getData().onSave) {
              this.ngxSmartModalService.get('student-assessment').getData().onSave();
            }
            this.ngxSmartModalService.get('student-assessment').close();
          }, error => {
            if (error.error?.detail) {
              alert(error.error?.detail);
            }
            this.ngxSmartModalService.get('student-assessment').close();
          });
        }

      }
    } else if (type === 'save' && this.markInfo.student_score.id && isMarkChanged) {
      let markData;
      /* редагування */
     // console.log(form.value.mark)
      if (!this.markInfo.is_middle_school) {
        markData = {
          abbr: form.value.mark ? (_.find(this.scoreList, ['id', form.value.mark]) as any).abbr : null,
          score: form.value.mark,
          teacher_comment: form.value.comment,
          id: this.markInfo.student_score.id,
          is_visible_for_student: this.is_visible_for_student,
          is_visible_for_parent: this.is_visible_for_parent,

        };
      } else {
        markData = {
          abbr: form.value.mark ? (_.find(this.scoreList, ['id', form.value.mark]) as any).abbr : null,
          score: form.value.mark,
          teacher_comment: form.value.comment,
          id: this.markInfo.student_score.id,
          is_visible_for_student: this.is_visible_for_student,
          is_visible_for_parent: this.is_visible_for_parent,
        };
      }

      this.classesService.editStudentScore({
        score: form.value.mark,
        teacher_comment: form.value.comment,
        id: this.markInfo.student_score.id,
        file_url: this.file,
        is_visible_for_student: this.is_visible_for_student,
        is_visible_for_parent: this.is_visible_for_parent,
      }).subscribe((response) => {

        markData.file_url = response.file_url;
        this.classesService.updateJournalLessonData$.next(markData);
        if (this.ngxSmartModalService.get('student-assessment').getData().onSave) {
          this.ngxSmartModalService.get('student-assessment').getData().onSave();
        }
        this.ngxSmartModalService.get('student-assessment').close();
      }, error => {
        if (error.error?.detail) {
          alert(error.error?.detail);
        }
        this.ngxSmartModalService.get('student-assessment').close();
      });

    } else if (type === 'save' && !isMarkChanged) {
      /* видалення */
      this.classesService.deleteStudentScore(this.markInfo.student_score.id).subscribe(response => {
        this.classesService.updateJournalLessonData$.next(this.markInfo.student_score.id);
        if (this.ngxSmartModalService.get('student-assessment').getData().onSave) {
          this.ngxSmartModalService.get('student-assessment').getData().onSave();
        }
        this.ngxSmartModalService.get('student-assessment').close();
      }, error => {
        console.log(error);
        this.ngxSmartModalService.get('student-assessment').close();
      });
    }
  }

  onLoadFile(data) {
    this.file = data[0] ? data[0].file : null;
  }

  deleteFile() {
    this.file = null;

    if (this.markInfo.student_score) {
      this.markInfo.student_score.file_url = null;
    }
  }

  downloadFile() {
    if (this.markInfo.student_score.file_url.match(/[\/.](gif|jpg|jpeg|png)$/i)) {
      window.open(this.markInfo.student_score.file_url, '_blank');
    } else {
      const link = document.createElement('a');

      link.href = this.markInfo.student_score.file_url;
      link.download = _.last(link.href.split('/'));
      link.click();
    }
  }

}
